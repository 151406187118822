import React, { useEffect, useState } from 'react';
import { useFloating } from '@floating-ui/react';
import { offset } from '@floating-ui/dom';
import classnames from 'classnames';
// import Image from 'next/image';
// import { firebaseAuth } from 'utils/firebaseConfig';
// import { onAuthStateChanged, signOut } from 'firebase/auth';
import { MixpanelConfig } from 'utils/mixpanelConfig';

import MaxWidthContainer from 'components/MaxWidthContainer';
// import RegisterPopup from 'components/Register/RegisterPopup';
// import MobileRegisterPopup from 'components/Register/MobileRegister';
// import { httpCall } from 'functions/httpAction';

// import { usePathname } from 'next/navigation';
import redirectToLogin from 'utils/redirectToLogin';
import { useRouter } from 'next/router';
// import { useFirebaseAuth } from 'contexts/FirebaseAuth';
// import { useAppLaunch } from 'contexts/AppLaunch';
import { useUserAuth } from 'contexts/UserAuth';
// import { useRouter as useNavigationRouter } from 'next/navigation';

const Menubar = (props) => {
  var backgroundOpacity = 0.0;
  var timer = null;
  var notificationShowIndex = 0;

  const { ...restProps } = props;

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-end',
    middleware: [offset(18)],
  });

  const [showProfileMenu, setShowProfileMenu] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refs.floating.current &&
        !refs.floating.current.contains(event.target) &&
        !refs.reference.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, []);

  // const { signOut } = useFirebaseAuth();
  const { user: sorraUser } = useUserAuth();
  // const { notifications } = useAppLaunch();
  // const userScore = sorraUser ? sorraUser.score : 0;
  // console.log({ sorraUser, userScore });

  const [notificationLoadedState, setNotificationLoadedState] = useState(false);
  const [arrNotificationState, setArrNotificationState] = useState([]);
  // const [backgroundOpacityState, setBackgroundOpacityState] = useState(0.0);
  const [blockNotificationState, setBlockNotificationState] = useState('');
  // const [blockAccountState, setBlockAccountState] = useState('');

  const [searchLoadedState, setSearchLoadedState] = useState(false);
  const [searchBrandState, setSearchBrandState] = useState('');
  const [searchProductState, setSearchProductState] = useState('');
  const [searchCategoryState, setSearchCategoryState] = useState('');
  const [searchCategoryIDState, setSearchCategoryIDState] = useState('');
  const [searchKeywordState, setSearchKeywordState] = useState('');
  const [searchDisplayState, setSearchDisplayState] = useState('');
  // const [userRegisterPopupShow, setUserRegisterPopupShow] = useState(false);
  // const [isUserLoggedIn, setIsUserLoggeedIn] = useState(false);
  // const [userScoreState, setUserScoreState] = useState(0);

  // const [isRedirectAlertShow, setIsRedirectAlertShow] = useState(false);
  // const [fetchedUser, setFetchedUser] = useState(null);

  const router = useRouter();
  // const navigationRouter = useNavigationRouter();
  const { asPath, pathname, query } = router;

  if (restProps.props.homeExtend == true) {
    backgroundOpacity = restProps.props.scrollPosition;
  } else {
    backgroundOpacity = 1.0;
  }

  if (
    restProps.props.notifications &&
    restProps.props.notifications != null &&
    notificationLoadedState == false
  ) {
    setArrNotificationState(restProps.props.notifications);
    setNotificationLoadedState(true);
  }

  if (searchLoadedState == false) {
    var searchLoaded = false;

    if (restProps.props.searchBrand && restProps.props.searchBrand != null) {
      setSearchBrandState(restProps.props.searchBrand);

      searchLoaded = true;
    }

    if (
      restProps.props.searchProduct &&
      restProps.props.searchProduct != null
    ) {
      setSearchProductState(restProps.props.searchProduct);

      searchLoaded = true;
    }

    if (
      restProps.props.searchCategory &&
      restProps.props.searchCategory != null
    ) {
      setSearchCategoryState(restProps.props.searchCategory);

      searchLoaded = true;
    }

    if (
      restProps.props.searchCategoryID &&
      restProps.props.searchCategoryID != null
    ) {
      setSearchCategoryIDState(restProps.props.searchCategoryID);

      searchLoaded = true;
    }

    if (
      restProps.props.searchKeyword &&
      restProps.props.searchKeyword != null
    ) {
      setSearchKeywordState(restProps.props.searchKeyword);

      searchLoaded = true;
    }

    if (searchLoaded == true) {
      setSearchLoadedState(true);

      var searchDisplayTmp = '';

      if (restProps.props.searchBrand != '') {
        searchDisplayTmp += restProps.props.searchBrand + ' ';
      }

      searchDisplayTmp += restProps.props.searchProduct;

      if (searchDisplayTmp != '' && restProps.props.searchCategory != '') {
        searchDisplayTmp += ' • ';
      }

      searchDisplayTmp += restProps.props.searchCategory;

      if (searchDisplayTmp != '' && restProps.props.searchKeyword != '') {
        searchDisplayTmp += ' • ';
      }

      searchDisplayTmp += restProps.props.searchKeyword;

      setSearchDisplayState(searchDisplayTmp);
    }
  }

  // const firSignout = () => {
  //   const auth = firebaseAuth;
  //   mixpanel.reset();
  //   signOut(auth).then((_) => {
  //     window.location.reload();
  //   });
  // };

  // async function fetchUserInfo(user) {
  //   const userToken = await user.getIdToken();
  //   const [success, error, statusCode, data] = await httpCall(
  //     'get',
  //     process.env.NEXT_PUBLIC_API_HOST + '/api/v2/me',
  //     userToken,
  //     null
  //   );
  //   if (success) {
  //     return data;
  //   }
  // }

  // async function checkSignin() {
  //   try {
  //     const auth = firebaseAuth;
  //     // const user = auth.currentUser;
  //     let score = 0;

  //     onAuthStateChanged(auth, async (user) => {
  //       if (user) {
  //         // const userInfo = await fetchUserInfo(user);
  //         // if (userInfo) {
  //         //   setFetchedUser(userInfo);
  //         //   if (userInfo.score) {
  //         //     score = userInfo.score;
  //         //     // setUserScoreState(userInfo.score);
  //         //   }
  //         //   const { phone_verified, post_signup_questionnaire_filled } =
  //         //     userInfo;
  //         //   if (!phone_verified || !post_signup_questionnaire_filled) {
  //         //     setIsRedirectAlertShow(true);
  //         //   } else {
  //         //     setIsRedirectAlertShow(false);
  //         //   }
  //         //   mixpanel.identify(`${userInfo.id}`);
  //         //   mixpanel.people.set({});
  //         // }
  //         // setBlockAccountState(
  //         //   <>
  //         //     <div className="flex gap-x-2">
  //         //       <button
  //         //         className="flex h-[41px] items-center justify-center rounded-[100px] border-[1px] border-[#E7E7E7] bg-[#FFFFFF] px-[8px] py-[4px] text-white"
  //         //         onClick={
  //         //           /*firSignout*/ () => {
  //         //             // signOut();
  //         //             window.location = '/profile';
  //         //           }
  //         //         }
  //         //       >
  //         //         <div className="mr-[8px] flex items-center justify-center rounded-[12px] bg-[#FCE1E5] ">
  //         //           <img
  //         //             src="/svgs/sorra-logo-small.svg"
  //         //             className="p-[6px] "
  //         //           />
  //         //         </div>
  //         //         <p className="text-sm text-black">${score}</p>
  //         //       </button>
  //         //     </div>
  //         //   </>
  //         // );
  //         // setIsUserLoggeedIn(true);
  //       } else {
  //         // setBlockAccountState(
  //         //   <>
  //         //     <div className="flex gap-x-4">
  //         //       <button
  //         //         className="menubar-normal-search-button flex h-[41px] items-center justify-center rounded-lg bg-sorra-pink px-5 text-white"
  //         //         onClick={() => {
  //         //           // console.log({ pathname, query, asPath })
  //         //           MixpanelConfig.track('signinup_from_nav');
  //         //           redirectToLogin(asPath);
  //         //         }}
  //         //       >
  //         //         <img src="/svgs/user-w.svg"></img>
  //         //         <p className="text-sm text-white">會員登陸</p>
  //         //       </button>
  //         //     </div>
  //         //   </>
  //         // );
  //         // setIsUserLoggeedIn(false);
  //       }
  //     });
  //   } catch (error) {
  //     // setBlockAccountState(
  //     //   <>
  //     //     <div className="flex gap-x-4">
  //     //       <button
  //     //         className="menubar-normal-search-button flex h-[41px] items-center justify-center rounded-lg bg-sorra-pink px-5 text-white"
  //     //         onClick={() => {
  //     //           MixpanelConfig.track('signinup_from_nav');
  //     //           console.log({ pathname, query, asPath });
  //     //           redirectToLogin(asPath);
  //     //         }}
  //     //       >
  //     //         <img src="/svgs/user-w.svg"></img>
  //     //         <p className="text-sm text-white">會員登陸</p>
  //     //       </button>
  //     //     </div>
  //     //   </>
  //     // );
  //   }
  // }

  function startTimer() {
    stopTimer();
    timer = setInterval(() => {
      if (arrNotificationState.length > 0) {
        if (notificationShowIndex + 1 >= arrNotificationState.length) {
          notificationShowIndex = 0;
        } else {
          notificationShowIndex += 1;
        }

        // console.log(notificationShowIndex)

        loadBlockNotification();
      }
    }, 3000);
  }

  function stopTimer() {
    clearInterval(timer);
    timer = null;
  }

  function loadBlockNotification() {
    var blockNotificationTmp = [];

    arrNotificationState.forEach((element, index) => {
      // console.log(element);

      blockNotificationTmp.push(
        <p
          key={element.id}
          className={
            'menubar-topbanner-item absolute text-sm text-white' +
            (index == notificationShowIndex ? ' highlighted' : '')
          }
        >
          <a href={element.url} target="_blank">
            {element.text}
          </a>
        </p>
      );
    });

    setBlockNotificationState(blockNotificationTmp);
  }

  function pressSearch() {
    if (restProps.props.sourceUI && restProps.props.sourceUI != null) {
      if (restProps.props.sourceUI == 'Home') {
        props.pressSearch();
      } else {
        if (searchDisplayState == '') {
          window.location = '/?search';
        } else {
          window.location =
            '/?search&product=' +
            searchProductState +
            '&category=' +
            searchCategoryState +
            '&categoryID=' +
            searchCategoryIDState +
            '&keyword=' +
            searchKeywordState;
        }
      }
    }
  }

  // useEffect(() => {
  //   checkSignin();
  // }, []);

  useEffect(() => {
    loadBlockNotification();
    startTimer();
  }, [arrNotificationState]);

  // useEffect(() => {
  //   if (isRedirectAlertShow && fetchedUser) {
  //     const { phone_verified, post_signup_questionnaire_filled } = fetchedUser;

  //     if (!phone_verified) {
  //       alert('請完成註冊程序。');
  //       window.location = '/phone';
  //       return;
  //     }

  //     if (phone_verified && !post_signup_questionnaire_filled) {
  //       alert('請完成註冊程序。');
  //       window.location = '/question1';
  //     }
  //   }
  // }, [isRedirectAlertShow]);

  useEffect(() => {
    if (sorraUser) {
      const { phone_verified, post_signup_questionnaire_filled } = sorraUser;

      if (!phone_verified) {
        alert('請完成註冊程序。');
        window.location = '/phone';
        return;
      }

      if (phone_verified && !post_signup_questionnaire_filled) {
        alert('請完成註冊程序。');
        window.location = '/question1';
      }
    }
  }, [sorraUser]);

  // useEffect(() => {}, [isUserLoggedIn]);

  return (
    <>
      <div className="fixed left-0 top-0 z-30 w-full">
        <div
          className={
            'menubar-topbanner relative inset-x-0 top-0 flex h-notify items-center justify-center bg-sorra-blue py-3' +
            (blockNotificationState == '' ? ' hidden' : '')
          }
        >
          {blockNotificationState}
        </div>

        <nav
          className={'menubar-normal relative w-full justify-center border-b'}
          style={{
            backgroundColor: 'rgba(255, 255, 255, ' + backgroundOpacity + ')',
            borderBottomColor: 'rgba(209, 219, 239, ' + backgroundOpacity + ')',
          }}
        >
          <MaxWidthContainer className="mx-auto hidden h-full flex-wrap items-center justify-between p-4 lg:flex">
            <div className="flex h-full items-center">
              <a href="/">
                <img
                  className="menubar-normal-logo"
                  alt="sorra"
                  src="/images/sorra.png"
                />
              </a>

              <div
                className={
                  'menubar-normal-search-frame relative' +
                  (backgroundOpacity < 1.0 ? ' transpirant' : '')
                }
                onClick={() => {
                  pressSearch();
                }}
              >
                <div className="menubar-normal-search-icon pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <img src="/svgs/search.svg"></img>
                </div>
                <input
                  type="text"
                  className="bg-gray-0 menubar-normal-search block w-full rounded-lg border border-gray-300 p-2.5 pl-10 text-sm text-sorra-gray-900 focus:border-blue-500 focus:ring-blue-500"
                  defaultValue={searchDisplayState}
                  placeholder="搜尋美妝產品・話題"
                />
              </div>
            </div>

            <div className="flex h-full items-center">
              <div className="relative flex items-center gap-x-12">
                <button
                  onClick={() => {
                    window.location = '/ranking';
                  }}
                >
                  美妝排行
                </button>
                <button
                  onClick={() => {
                    window.location = '/rewards';
                  }}
                >
                  兌換禮物
                </button>
                <button
                  onClick={() => {
                    window.location = '/campaign';
                  }}
                >
                  活動
                </button>
                <button
                  onClick={() => {
                    window.location = '/rewards?tab=code';
                  }}
                >
                  優惠碼
                </button>
                {!sorraUser && (
                  <button
                    onClick={() => {
                      window.location = '/membership ';
                    }}
                  >
                    會員制度
                  </button>
                )}
                {/* {blockAccountState} */}
                {sorraUser ? (
                  // ref={refs.setReference}
                  <div className="flex gap-x-2">
                    <button
                      ref={refs.setReference}
                      className="flex h-[41px] items-center justify-center rounded-[100px] border-[1px] border-[#E7E7E7] bg-[#FFFFFF] px-[8px] py-[4px] text-white"
                      onClick={() => {
                        setShowProfileMenu(!showProfileMenu);
                        // signOut();
                        // console.log('gggggg');
                        // window.location = '/profile';
                      }}
                    >
                      <div className="mr-[8px] flex items-center justify-center rounded-[12px] bg-[#FCE1E5]">
                        <img
                          src="/svgs/sorra-logo-small.svg"
                          className="p-[6px]"
                        />
                      </div>
                      <p className="text-sm text-black">
                        ${sorraUser.score || 0}
                      </p>
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-x-4">
                    <button
                      className="menubar-normal-search-button flex h-[41px] items-center justify-center rounded-lg bg-sorra-pink px-5 text-white"
                      onClick={() => {
                        // console.log({ pathname, query, asPath })
                        MixpanelConfig.track('signinup_from_nav');
                        redirectToLogin(asPath);
                      }}
                    >
                      <img src="/svgs/user-w.svg"></img>
                      <p className="text-sm text-white">會員登陸</p>
                    </button>
                  </div>
                )}
                {showProfileMenu && (
                  <div
                    ref={refs.setFloating}
                    style={{
                      ...floatingStyles,
                      boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.10)',
                    }}
                    className="flex min-w-[234px] flex-col gap-y-8 rounded-lg bg-white px-[30px] py-[22px]"
                  >
                    <button
                      className="text-left"
                      onClick={() => {
                        router.push('/profile');
                        setShowProfileMenu(false);
                      }}
                    >
                      我的美妝檔案
                    </button>
                    <button
                      className="text-left"
                      onClick={() => {
                        router.push(`/p/${sorraUser.referral_code}`);
                        setShowProfileMenu(false);
                      }}
                    >
                      個人中心
                    </button>
                  </div>
                )}
              </div>
            </div>
          </MaxWidthContainer>

          <MaxWidthContainer
            className={
              'mx-auto flex h-full flex-wrap items-center justify-between p-4 lg:hidden' +
              (restProps.props.sourceUI == 'Search' ? ' hidden' : '')
            }
          >
            <a href="/">
              <div className="flex h-full items-center">
                <img
                  className="menubar-normal-logo"
                  alt="sorra"
                  src="/images/sorra.png"
                />
              </div>
            </a>

            <div className="flex h-full items-center">
              <div className="relative flex items-center gap-x-6">
                <button
                  onClick={() => {
                    pressSearch();
                  }}
                >
                  <img src="/svgs/search.svg"></img>
                </button>
                <div className="flex gap-x-4">
                  {!sorraUser ? (
                    <button
                      className="menubar-normal-search-button flex h-[41px] items-center justify-center rounded-lg bg-sorra-pink px-3 text-white"
                      onClick={() => {
                        redirectToLogin(asPath);
                      }}
                    >
                      <img src="/svgs/user-w.svg"></img>
                      <p className="text-sm text-white">會員登陸</p>
                    </button>
                  ) : (
                    <button
                      className="flex h-[41px] items-center justify-center rounded-[100px] border-[1px] border-[#E7E7E7] bg-[#FFFFFF] px-[8px] py-[4px] text-white"
                      onClick={
                        /*firSignout*/ () => {
                          window.location = '/profile';
                        }
                      }
                    >
                      <div className="mr-[8px] flex items-center justify-center rounded-[12px] bg-[#FCE1E5]">
                        <img
                          src="/svgs/sorra-logo-small.svg"
                          className="p-[6px]"
                        />
                      </div>
                      <p className="text-sm text-black">
                        ${sorraUser.score || 0}
                      </p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </MaxWidthContainer>

          <MaxWidthContainer
            className={
              'mx-auto flex h-full flex-nowrap items-center justify-between p-4 lg:hidden' +
              (restProps.props.sourceUI == 'Search' ? '' : ' hidden')
            }
          >
            <a href="/">
              <div className="flex h-full items-center pr-[16px]">
                <img
                  src="/svgs/chevron-left.svg"
                  className="h-[24px] w-[24px]"
                />
              </div>
            </a>

            <div
              className={'relative grow'}
              onClick={() => {
                pressSearch();
              }}
            >
              <div className="menubar-normal-search-icon pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <img src="/svgs/search.svg"></img>
              </div>
              <input
                type="text"
                id=""
                className="bg-gray-0 block w-full rounded-lg border border-gray-300 p-2.5 pl-14 text-sm text-sorra-gray-900 focus:border-blue-500 focus:ring-blue-500"
                defaultValue={searchDisplayState}
                placeholder="搜尋美妝產品・話題"
              />
            </div>
          </MaxWidthContainer>
        </nav>
      </div>
      {/* {userRegisterPopupShow ? (
        <RegisterPopup
          isShow={userRegisterPopupShow}
          setIsShow={setUserRegisterPopupShow}
        />
      ) : null}
      {userRegisterPopupShow ? (
        <MobileRegisterPopup
          isShow={userRegisterPopupShow}
          setIsShow={setUserRegisterPopupShow}
        />
      ) : null} */}
    </>
  );
};

export default Menubar;
